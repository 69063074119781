
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 菜单
 * @author gan
 * @date: 2020/12/17 11:09
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005492'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'yms_statistics',
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005813'),
      icon: '&#xe749;',
      type: 'productList',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002218'),
          path: '/productList',
          menuKey: 'yms_productList',
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002662'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006917'),
              path: '/productDetails',
              menuKey: 'yms_productDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002810'),
          path: '/category',
          menuKey: 'yms_category',
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006918'),
          path: '/sizeTemplateManage',
          menuKey: 'yms_sizeTemplateManage',
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006919'),
              path: '/updateSizeTemplate',
              menuKey: 'yms_updateSizeTemplate',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006920'),
          path: '/approvalList',
          menuKey: 'yms_approvalList',
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006921'),
              path: '/approvalDetails',
              menuKey: 'yms_approvalDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002665'),
              path: '/findSimilarProducts',
              menuKey: 'yms_findSimilarProducts',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002229'),
          path: '/itemAttributes',
          menuKey: 'yms_itemAttributes'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002657'),
          path: '/productLabel',
          menuKey: 'yms_productLabel'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006922'),
          path: '/productSettings',
          menuKey: 'yms_productSettings',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001149'),
      icon: '&#xe665;',
      type: 'orderList',
      children: [
        {
          name: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001449')}`,
          path: '/orderList',
          menuKey: 'yms_orderList',
          children: [
            {
              name: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005082')}`,
              path: '/orderListDetails',
              menuKey: 'yms_orderListDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006923'),
              path: '/supplierOrderDetails',
              menuKey: 'yms_supplierOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006924'),
          path: '/distributorStockList',
          menuKey: 'yms_distributorStockList'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006925'),
          path: '/supplierStockList',
          menuKey: 'yms_supplierStockList',
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006926'),
              path: '/supplierStockOrderDetails',
              menuKey: 'yms_supplierStockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002228'),
          path: '/supplierOrders',
          menuKey: 'yms_supplierOrders'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006927'),
          path: '/orderPaymentSettings',
          menuKey: 'yms_orderPaymentSettings',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006928'),
          path: '/orderCollectionAccount',
          menuKey: 'yms_orderCollectionAccount'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003777'),
          path: '/orderPaymentReconciliation',
          menuKey: 'yms_orderPaymentReconciliation'
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000018'),
      icon: '&#xe679;',
      type: 'wms',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002220'),
          path: '/wms/inventoryLevels',
          menuKey: 'yms_wms_inventoryLevels'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006929'),
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006930'),
              path: '/billLadingManage',
              menuKey: 'yms_billLadingManage',
              children: [
                {
                  name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006931'),
                  path: '/billLadingManageDetails',
                  menuKey: 'yms_billLadingManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001957'),
              path: '/billWarehousing',
              menuKey: 'yms_billWarehousing'
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001958'),
              path: '/commoditySorting',
              menuKey: 'yms_commoditySorting'
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002219'),
              path: '/commoditySticker',
              menuKey: 'yms_commoditySticker'
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001961'),
              path: '/commodityShelves',
              menuKey: 'yms_commodityShelves'
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006006'),
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002563'),
              path: '/assembleGeneratePickingList',
              menuKey: 'yms_assembleGeneratePickingList'
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006932'),
              path: '/assembleGeneratePickingDetails',
              menuKey: 'yms_assembleGeneratePickingDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006923'),
              path: '/assembleSupplierOrderDetails',
              menuKey: 'yms_assembleSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006933'),
              path: '/assemblePickingList',
              menuKey: 'yms_assemblePickingList'
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002528'),
              path: '/assemblePickingListDetails',
              menuKey: 'yms_assemblePickingListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001345'),
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002563'),
              path: '/wms/generateOrderList',
              menuKey: 'yms_wms_generateOrderList'
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006932'),
              path: '/generateOrderDetails',
              menuKey: 'yms_generateOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006923'),
              path: '/generateSupplierOrderDetails',
              menuKey: 'yms_generateSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006933'),
              path: '/wms/pickList',
              menuKey: 'yms_wms_pickList'
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002528'),
              path: '/wms/pickListDetails',
              menuKey: 'yms_wms_pickListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006106'),
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006934'),
              path: '/wms/sortOrderSetting',
              menuKey: 'yms_wms_sortOrderSetting'
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001962'),
              path: '/wms/sorting',
              menuKey: 'yms_wms_sorting'
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001964'),
              path: '/wms/packWorking',
              menuKey: 'yms_wms_packWorking'
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001965'),
              path: '/sortingOut',
              menuKey: 'yms_sortingOut'
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006935'),
              path: '/handoverSheetManage',
              menuKey: 'yms_handoverSheetManage',
              children: [
                {
                  name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006936'),
                  path: '/handoverSheetManageDetails',
                  menuKey: 'yms_handoverSheetManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006937'),
      icon: '&#xe668;',
      type: 'supplierList',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006938'),
          path: '/supplierList',
          menuKey: 'yms_supplierList'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006939'),
          path: '/supplierAuditList',
          menuKey: 'yms_supplierAuditList'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006940'),
          path: '/supplierLevel',
          menuKey: 'yms_supplierLevel'
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006941'),
      icon: '&#xe699;',
      type: 'distributorList',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006942'),
          path: '/customerList',
          menuKey: 'yms_customerList',
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002466'),
          path: '/customerLabel',
          menuKey: 'yms_customerLabel',
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002465'),
          path: '/customerGroups',
          menuKey: 'yms_customerGroups',
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002467'),
          path: '/customerGrade',
          menuKey: 'yms_customerGrade'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006943'),
          path: '/tuokeLink',
          menuKey: 'yms_tuokeLink'
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001150'),
      icon: '&#xe668;',
      type: 'supplierBillManage',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006944'),
          path: '/supplierBillManage',
          menuKey: 'yms_supplierBillManage',
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006945'),
              path: '/supplierBillDetails',
              menuKey: 'yms_supplierBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002230'),
          path: '/customerBill',
          menuKey: 'yms_customerBill',
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006946'),
              path: '/customerBillDetails',
              menuKey: 'yms_customerBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002231'),
          path: '/logisticsBill',
          menuKey: 'yms_logisticsBill',
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006947'),
              path: '/logisticsBillDetails',
              menuKey: 'yms_logisticsBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002232'),
          path: '/warehouseBill',
          menuKey: 'yms_warehouseBill',
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006948'),
              path: '/warehouseBillDetails',
              menuKey: 'yms_warehouseBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002874'),
          path: '/financialJournal',
          menuKey: 'yms_financialJournal'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002225'),
          path: '/rechargeRecord',
          menuKey: 'yms_rechargeRecord'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006949'),
          path: '/passwordManage',
          menuKey: 'yms_passwordManage'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002224'),
          path: '/subsidyReview',
          menuKey: 'yms_subsidyReview'
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006950'),
      icon: '&#xe68e;',
      type: 'logisticsManage',
      children: [
        {
          name: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003044')}`,
          path: '/ymsLogistics',
          menuKey: 'yms_ymsLogistics'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006951'),
          path: '/basicLogistics',
          menuKey: 'yms_basicLogistics'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006952'),
          path: '/smartLogisticsRule',
          menuKey: 'yms_smartLogisticsRule'
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001165'),
      icon: '&#xe68f;',
      type: 'warehouseManage',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006953'),
          path: '/warehouseManage',
          menuKey: 'yms_warehouseManage'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006954'),
          path: '/reservoirManage',
          menuKey: 'yms_reservoirManage'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006955'),
          path: '/locationManage',
          menuKey: 'yms_locationManage'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006956'),
          path: '/messageNotification',
          menuKey: 'yms_messageNotification'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001691'),
          path: '/exchangeRateConfig',
          menuKey: 'yms_exchangeRateConfig'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006957'),
          path: '/containerManage',
          menuKey: 'yms_containerManage'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006958'),
          path: '/containerSettings',
          menuKey: 'yms_containerSettings'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006959'),
          path: '/addressLibraryManage',
          menuKey: 'yms_addressLibraryManage'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006960'),
          path: '/processConfiguration',
          menuKey: 'yms_processConfiguration'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006961'),
          path: '/iossTaxRateSetting',
          menuKey: 'yms_iossTaxRateSetting'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006962'),
          path: '/mallConfiguration',
          menuKey: 'yms_mallConfiguration',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001001'),
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006963'),
              path: '/declarationRules',
              menuKey: 'yms_declarationRules'
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001149'),
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006964'),
              path: '/automaticDistributionRules',
              menuKey: 'yms_automaticDistributionRules'
            }
          ]
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006965'),
      icon: '&#xe68b;',
      type: 'helpCenter',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000036'),
          path: '/helpCenter',
          menuKey: 'yms_helpCenter',
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006966'),
          path: '/questionnaireInvestigation',
          menuKey: 'yms_questionnaireInvestigation',
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002165'),
              path: '/questionnaireStatistics',
              menuKey: 'yms_questionnaireStatistics',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002259'),
              path: '/addQuestionnaire',
              menuKey: 'yms_addQuestionnaire',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006967'),
              path: '/editQuestionnaire',
              menuKey: 'yms_editQuestionnaire',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006968'),
          path: '/operatingInstructions',
          menuKey: 'yms_operatingInstructions',
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000886'),
          id: 'toSystemAnnouncement',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'yms_systemAnnouncement',
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006969'),
      icon: '&#xe691;',
      type: 'dataCenter',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002222'),
          path: '/deliveryDetails',
          menuKey: 'yms_deliveryDetails',
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006970'),
      icon: '&#xe66a;',
      type: 'activityManage',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006971'),
          path: '/siteManage',
          menuKey: 'yms_siteManage',
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006972'),
          path: '/activityManage',
          menuKey: 'yms_activityManage',
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006973'),
          path: '/editActivity',
          menuKey: 'yms_editActivity',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006974'),
          path: '/advertisingManage',
          menuKey: 'yms_advertisingManage',
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004136'),
          path: '/rotationChart',
          menuKey: 'yms_rotationChart',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004103'),
          path: '/vajraDistrict',
          menuKey: 'yms_vajraDistrict',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004137'),
          path: '/topAdvertisingSpace',
          menuKey: 'yms_topAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004138'),
          path: '/sideAdvertisingSpace',
          menuKey: 'yms_sideAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004139'),
          path: '/infoAdvertisingSpace',
          menuKey: 'yms_infoAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003046'),
          path: '/subsidyRules',
          menuKey: 'yms_subsidyRules',
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006975'),
          path: '/distributorFeeAllocation',
          menuKey: 'yms_distributorFeeAllocation',
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000290'),
      path: '/messageCenter',
      icon: '&#xe8be;',
      menuKey: 'yms_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      type: 'messageCenter',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006976'),
          path: '/messageMailboxes',
          menuKey: 'yms_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006977'),
      icon: '&#xe693;',
      path: '/employeeList',
      menuKey: 'yms_employeeList',
      type: 'employeeList',
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006978'),
      icon: '&#xe664;',
      type: 'importTask',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006979'),
          path: '/importTask',
          menuKey: 'yms_importTask'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006980'),
          path: '/exportTask',
          menuKey: 'yms_exportTask'
        }
      ]
    }
  ]
};
