
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 分销商后台管理系统--菜单
 * @author jing
 * @date 2021/1/19 9:49
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 * @attribute distributorType 分销商类型(ALIGJ: 阿里国际分销、YMS：云卖分销商)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006999'),
      icon: '&#xe6b2;',
      path: '/distributionMarket',
      menuKey: 'distributor_distributionMarket',
      distributorType: ['ALIGJ']
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005813'),
      icon: '&#xe618;',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000876'),
          path: '/myInventory',
          menuKey: 'distributor_myInventory',
          distributorType: ['YMS']
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007000'),
          path: '/commodityList',
          menuKey: 'distributor_commodityList',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007001'),
          path: '/generateSalesRecords',
          menuKey: 'distributor_generateSalesRecords',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000008'),
          type: 'toListing',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在角色管理中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'distributor_shelfTools',
          distributorType: ['YMS', 'ALIGJ']
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007002'),
      icon: '&#xe6fe;',
      path: '/automaticRules',
      menuKey: 'distributor_automaticRules',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007003'),
      icon: '&#xe604;',
      path: '/platformStoreList',
      menuKey: 'distributor_platformStoreList',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000079'),
      icon: '&#xe63a;',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007004'),
          path: '/collectionSupplier',
          menuKey: 'distributor_collectionSupplier',
          distributorType: ['YMS']
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001149'),
      icon: '&#xe668;',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004626'),
          path: '/platformOrder',
          menuKey: 'distributor_platformOrder',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007005'),
              path: '/platformDetails',
              menuKey: 'distributor_platformDetails',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007006'),
              path: '/addTemuOrder',
              menuKey: 'distributor_addTemuOrder',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001449')}`,
          path: '/myOrder',
          menuKey: 'distributor_myOrder',
          distributorType: ['YMS'],
          children: [
            {
              name: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005082')}`,
              path: '/myOrderDetails',
              menuKey: 'distributor_myOrderDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005108'),
              path: '/addCloudSalesOrder',
              menuKey: 'distributor_addCloudSalesOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004627'),
          path: '/distributorStockList',
          menuKey: 'distributor_distributorStockList',
          distributorType: ['YMS'],
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001491'),
              path: '/createBackupOrder',
              menuKey: 'distributor_createBackupOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006983'),
              path: '/distributorStockListDetails',
              menuKey: 'distributor_distributorStockListDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007007'),
          path: '/paymentManagement',
          menuKey: 'distributor_paymentManagement',
          distributorType: ['YMS'],
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007008'),
          path: '/orderImportTemplateList',
          menuKey: 'distributor_orderImportTemplateList',
          distributorType: ['YMS'],
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001150'),
      icon: '&#xe645;',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002225'),
          path: '/rechargeRecord',
          menuKey: 'distributor_rechargeRecord',
          distributorType: ['YMS'],
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002874'),
          path: '/financialJournal',
          menuKey: 'distributor_financialJournal',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006950'),
      icon: '&#xe68e;',
      children: [
        {
          name: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003044')}`,
          path: '/logisticsSettings',
          menuKey: 'distributor_logisticsSettings',
          distributorType: ['YMS'],
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001187'),
          path: '/ownLogistics',
          menuKey: 'distributor_ownLogistics',
          distributorType: ['YMS'],
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006952'),
          path: '/smartRule',
          menuKey: 'distributor_smartRule',
          distributorType: ['YMS'],
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007009'),
      icon: '&#xe61b;',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007010'),
          path: '/roleManage',
          menuKey: 'distributor_roleManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007011'),
          path: '/userManage',
          menuKey: 'distributor_userManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000290'),
      path: '/newsList',
      menuKey: 'distributor_newsList',
      distributorType: ['YMS', 'ALIGJ'],
      menuHide: true,
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007012'),
          path: '/newsListDetails',
          menuKey: 'distributor_newsListDetails',
          distributorType: ['YMS', 'ALIGJ'],
          menuHide: true
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007013'),
      icon: '&#xe627;',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000646'),
          path: '/basicInformation',
          menuKey: 'distributor_basicInformation',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007014'),
          path: '/securityInformation',
          menuKey: 'distributor_securityInformation',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004587'),
              path: '/modifyMobile',
              menuKey: 'distributor_modifyMobile',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004589'),
              path: '/modifyMailbox',
              menuKey: 'distributor_modifyMailbox',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004846'),
              path: '/modifyPassword',
              menuKey: 'distributor_modifyPassword',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004531'),
          path: '/iossManageList',
          menuKey: 'distributor_iossManageList',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006978'),
      icon: '&#xe6a9;',
      type: 'importTask',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006979'),
          path: '/importTask',
          menuKey: 'distributor_importTask',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006980'),
          path: '/exportTask',
          menuKey: 'distributor_exportTask',
          distributorType: ['YMS', 'ALIGJ'],
        }
      ]
    },
    {
      name: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006998')}`,
      type: 'ymsShopping',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1007015'),
          menuKey: 'shop_shopList',
          distributorType: ['YMS'],
        }
      ]
    }
  ]
};
