<template>
  <div class="left_nav_box" v-if="!isCollapsed" id="left_nav_box" :style="leftStyles">
    <div class="search_keywords" v-if="showKeyWords">
      <Input v-model.trim="keywords" :placeholder="$t('key1000005')" @on-enter="searchKeywords" clearable/>
    </div>
    <local-menu
      ref="menuList"
      :menuData="menuData"
      @updateActiveName="updateActiveName"
      :openNames="openNames"
      :activeName="activeName">
    </local-menu>
  </div>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import menu from '@/components/layout/menu/menu';
import ymsMenu from '@/config/menu/yms';
import supplierMenu from "@/config/menu/supplier";
import api from '@/api/';
import {commonSessionStorage, verifyLoginTicket} from "@/utils/common";

export default {
  name: 'leftMenu',
  mixins: [Mixin],
  props: {
    isCollapsed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    localMenu: menu
  },
  data() {
    return {
      menuData: null,
      originalMenuData: null,
      openNames: [], // 展开name 默认全展示
      activeName: '', // 默认第一个
      isAdmin: this.$store.state.isAdmin, // 管理员
      leftStyles: {
        paddingBottom: '0px',
      },
      keywords: '',
      showKeyWords: false,
      topNavActiveName: localStorage.getItem('topNavActiveName') || 'operationManage', // 默认选中的顶部导航菜单
    };
  },
  created() {
    this.initWebSocket();
    this.setMenuData(this.topNavActiveName);
  },
  methods: {
    setMenuId(menuData) {
      /**
       * @Description: 给带单设置name id
       * @author gan
       * @date: 2020/12/17 13:42
       * @params:
       */
      if (!menuData) return [];
      let v = this;

      function setId(data, id) {
        data = data.filter(i => !i.menuHide);
        data.forEach((i, j) => {
          if (id) {
            i.id = id + '-' + j;
          } else {
            i.id = j + '';
          }
          if (!v.activeName && !i.children) {
            v.activeName = i.id;
          }
          if (v.$route.path === i.path) {
            v.activeName = i.id;
          } else {
            let name = localStorage.getItem('activeName');
            if (name) {
              v.activeName = name;
            }
          }
          localStorage.setItem('activeName', v.activeName);
          v.openNames.push(i.id);
          if (i.children && i.children.filter(k => !k.menuHide).length > 0) {
            i.children = setId(i.children, i.id);
          } else {
            i.children = null;
          }
        });
        return data;
      }

      return setId(menuData);
    },
    // 过滤菜单数据（睿栀）
    filterMenuDta(menu) {
      // 尺码表模板
      const filterMenuKey = ['yms_sizeTemplateManage']; // 过滤key
      let typeList = ['productList']; // 过滤的type
      return menu.filter(i => {
        if (typeList.includes(i.type)) {
          i.children = i.children.filter(j => !filterMenuKey.includes(j.menuKey));
        }
        return i;
      });
    },
    /**
     * @Description:设置菜单数据
     * @author gan
     * @date: 2020/12/17 11:16
     */
    setMenuData(name) {
      let ymsMenuData = JSON.parse(JSON.stringify(ymsMenu.menu));
      this.topNavActiveName = name;
      let pathname = window.location.pathname;
      // 平台没有开通服装大类时，不显示【尺码表模板】菜单
      let val = commonSessionStorage.getItem('openClothingCategories') || this.$store.state.openClothingCategories;
      if (!val) {
        ymsMenuData = this.filterMenuDta(ymsMenu.menu);
      }
      let pathnameList = [
        {pathname: '/yms.html', menu: ymsMenuData},
        {pathname: '/supplier.html', menu: JSON.parse(JSON.stringify(supplierMenu[this.topNavActiveName]))}
      ];
      pathnameList.map((item) => {
        if (pathname.includes(item.pathname)) {
          if (this.isAdmin) {
            this.menuData = this.setMenuId(item.menu);
            this.originalMenuData = this.setMenuId(item.menu);
            localStorage.setItem('pagesPermissions', JSON.stringify(this.menuData));
            this.defaultSelectedMenu(this.menuData);
            this.getMenuPendingData();
          } else {
            this.handlerNoPermission(item.menu);
            this.defaultSelectedMenu(this.menuData);
            this.getMenuPendingData();
          }
        }
      });
      if (pathname.includes('/yms.html')) {
        let talg = localStorage.getItem('switchSidebarMenu');
        this.showKeyWords = JSON.parse(talg);
      }
    },
    // 更新 activeName
    updateActiveName(value) {
      this.activeName = value;
      this.keywords = '';
      let talg = localStorage.getItem('switchSidebarMenu');
      if (talg && this.system === 'yms') {
        this.showKeyWords = JSON.parse(talg);
      }
    },

    // 处理过滤掉没有权限的树状数据
    handerMenuData(data, roleData) {
      if (data) {
        for (let i = data.length - 1; i >= 0; i--) {
          if (data[i].children && !data[i].children[0].menuHide) {
            let arr = [];
            data[i].children.filter((ele) => {
              if (roleData.indexOf(ele.menuKey) > -1) {
                arr.push(ele);
              }
            });
            data[i].children = arr;
            if (data[i].children.length <= 0) {
              data.splice(i, 1);
            }
          } else {
            if (!data[i].jurisdictionHide) {
              if (roleData.indexOf(data[i].menuKey) <= -1) {
                data.splice(i, 1);
              }
            }
          }
        }
        return data;
      }
    },

    // 过滤掉没有权限的菜单
    handlerNoPermission(supplierMenuData) {
      let v = this;
      let roleData = v.$store.state.roleData || commonSessionStorage.getItem('roleData');
      if (roleData) {
        function leftMenu(menu) {
          menu.forEach((item) => {
            if (item.children && item.children.length > 0) {
              v.handerMenuData(item.children, roleData);
            }
          });
        }

        leftMenu(supplierMenuData);
        if (supplierMenuData) {
          for (let i = supplierMenuData.length - 1; i >= 0; i--) {
            if (supplierMenuData[i].menuKey) {
              if (!supplierMenuData[i].jurisdictionHide) {
                if (roleData.indexOf(supplierMenuData[i].menuKey) <= -1) {
                  supplierMenuData.splice(i, 1);
                }
              }
            } else {
              if (supplierMenuData[i].children) {
                if (supplierMenuData[i].children.length <= 0) {
                  supplierMenuData.splice(i, 1);
                }
              }
            }
          }
          v.menuData = this.setMenuId(supplierMenuData);
          v.originalMenuData = this.setMenuId(supplierMenuData);
          localStorage.setItem('pagesPermissions', JSON.stringify(v.menuData));
        }
      }
    },
    // 搜索侧边栏菜单
    searchKeywords() {
      let v = this;
      v.menuData = JSON.parse(JSON.stringify(v.originalMenuData));
      if (v.menuData.length > 0) {
        let menuData = [];
        let value = v.keywords;

        function handerMenu(menu) {
          menu.forEach((item) => {
            if (item.children && item.children.length > 0) {
              handerMenu(item.children);
              menuData.push(item);
            } else {
              menuData.push(item);
            }
          });
        }

        handerMenu(v.menuData);

        if (menuData.length > 0) {
          v.openNames = [];
          let arr = [];
          if (value !== '') {
            for (let i = 0; i < menuData.length; i++) {
              let item = menuData[i];
              v.openNames.push(item.id);
              let reg = new RegExp(value, 'gi'); // 'g' 用于全局匹配，'i' 用于大小写不敏感
              if (reg.test(item.name)) {
                item.name = item.name.replace(reg, '<span class="keywords_highlight">$&</span>');
                arr.push(item);
              }
            }
            let parent = v.$refs.menuList.$el;
            v.$nextTick(() => {
              if (v.$refs.menuList.$refs.leftMenu) {
                v.$refs.menuList.$refs.leftMenu.updateOpened();
                v.$refs.menuList.$refs.leftMenu.updateActiveName();
              }
              setTimeout(() => {
                if (arr.length > 0) {
                  v.$nextTick(() => {
                    parent.scrollTo(0, 0);
                    let target = document.getElementsByClassName('keywords_highlight');
                    let rollingDistance = target[0].getBoundingClientRect().top - 120;
                    parent.scrollTo(0, rollingDistance);
                  });
                } else {
                  v.$Message.warning(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000006'));
                  return false;
                }
              }, 500);
            });
          } else {
            v.$Message.warning(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000007'));
            return false;
          }
        }
      }
    },
    // 默认选中有权限的第一个菜单
    defaultSelectedMenu(data) {
      let v = this;
      if (v.system === 'supplier') {
        if (data && data.length > 0) {

          function treeToList(tree, result = []) {
            tree.forEach(node => {
              result.push(node)
              node.children && treeToList(node.children, result)
            })
            return result
          }

          let newList = treeToList(supplierMenu[v.topNavActiveName])
          if (newList.length > 0) {
            let obj = newList[0];
            let talg = newList.every((item) => {
              return item.path !== v.$route.path;
            });
            if (talg) {
              v.activeName = obj.id;
              localStorage.setItem('activeName', v.activeName);
              v.$router.push(obj.path);
            }
          }
        }
      }
    },
    // 初始化 Websocket
    initWebSocket() {
      let v = this;
      v.$webSocket.socket.onmessage = function (e) {
        v.$webSocket.heartReset().heartStart();
        let data = '';
        if (e) {
          try {
            data = JSON.parse(e.data);
          } catch (e) {
            data = null;
          }
          if (data) {
            if (data.type === 'pending') {
              v.getMenuPendingData(true);
            }
          }
        }
      };
    },
    // 获取菜单栏待处理的数量的数据
    getMenuPendingData(value) {
      let v = this;
      if (value) {
        handleData();
      } else {
        let menuPendingCountData = commonSessionStorage.getItem('menuPendingCountData');
        if (menuPendingCountData) {
          v.handleMenuPendingData(menuPendingCountData)
        } else {
          handleData();
        }
      }

      function handleData() {
        let urlObj = {
          distributor: api.get_ymsDistributorCommon_getPendingCount,
          supplier: api.get_ymsSupplierCommon_getPendingCount
        }
        let url = urlObj[v.system]
        v.axios.get(url).then(response => {
          if (response) {
            if (response.data.code === 0) {
              let data = response.data.datas;
              if (data) {
                commonSessionStorage.setItem('menuPendingCountData', data);
                v.handleMenuPendingData(data)
              }
            }
          }
        });
      }
    },
    // 处理菜单栏待处理数量的数据
    handleMenuPendingData(data) {
      let v = this;
      if (v.menuData.length > 0) {
        // 处理待处理数量
        function treeToList(tree, result = []) {
          tree.forEach(node => {
            result.push(node)
            node.children && treeToList(node.children, result)
          });
          return result
        }

        let newList = treeToList(v.menuData);
        if (newList.length > 0) {
          newList.map((item, index) => {
            for (let key in data) {
              if (item.menuKey === key) {
                v.$set(newList[index], 'quantity', data[key]);
              }
            }
          });
          v.$forceUpdate();
        }
      }
    }
  },
  watch: {
    keywords: {
      handler(value) {
        let v = this;
        if (value === '') {
          v.menuData = JSON.parse(JSON.stringify(v.originalMenuData));
        }
      },
      deep: true
    },
    showKeyWords: {
      handler(value) {
        if (value) {
          this.leftStyles.paddingBottom = '60px';
        } else {
          this.leftStyles.paddingBottom = '0px';
        }
      },
      deep: true,
      immediate: true
    },
    '$store.state.updateSystemMenu': {
      handler(val) {
        let v = this;
        if (val) {
          v.activeName = localStorage.getItem('activeName');
          v.$nextTick(function () {
            if (v.$refs.menuList.$refs.leftMenu) {
              v.$refs.menuList.$refs.leftMenu.updateOpened();
              v.$refs.menuList.$refs.leftMenu.updateActiveName();
            }
          });
        }
      },
      deep: true
    },
    '$store.state.updatePendingCount': {
      handler(val) {
        if (val) {
          this.getMenuPendingData(true);
        }
      },
      deep: true
    }
  }
};
</script>
<style lang="less" scoped>
.left_nav_box {
  position: fixed;
  bottom: 0;
  top: 50px;
  background-color: #fff;
  z-index: 1050;

  .ivu-menu {
    height: 100%;
    overflow: auto;
    z-index: 1050;
  }

  .search_keywords {
    width: 100%;
    background-color: #fff;
    position: sticky;
    left: 0;
    top: 0;
    height: 50px;
    line-height: 48px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
    padding: 0 15px;
    z-index: 100;
    margin-bottom: 10px;
  }
}

.select_system {
  padding: 15px;
}

.style_dropdownbox {
  max-height: 280px;
  min-width: 210px !important;

  .system_link {
    color: #515a6e;
  }

  .system_link:hover {
    color: #2b85e4;
    text-decoration: underline;
  }
}
</style>
