
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';
export default {
  // 运营管理
  operationManage: [
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005492'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'supplier_statistics',
      hideCrumbs: true, // 隐藏面包屑
      guidanceModule: false, // 指引模块（默认开启）  true：开启指引模块    false  不开启指引模块
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006981'),
      icon: '&#xe668;',
      type: 'supplierOrder',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006982'),
          path: '/supplierOrderList',
          menuKey: 'supplier_supplierOrderList',
          guidanceModule: true
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004627'),
          path: '/stockList',
          menuKey: 'supplier_stockList',
          guidanceModule: true,
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005739'),
              path: '/initiateStocking',
              menuKey: 'supplier_initiateStocking',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006983'),
              path: '/stockOrderDetails',
              menuKey: 'supplier_stockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006984'),
          path: '/shipmentRecord',
          menuKey: 'supplier_shipmentRecord',
          guidanceModule: true,
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006985'),
              path: '/shipmentRecordDetails',
              menuKey: 'supplier_shipmentRecordDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005716'),
              path: '/additionalGoods',
              menuKey: 'supplier_additionalGoods',
              menuHide: true // 菜单不展示
            }
          ]
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006986'),
      icon: '&#xe678;',
      type: 'productCenter',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005813'),
          path: '/productCenter/productGoods',
          menuKey: 'supplier_productCenter_productGoods',
          guidanceModule: true,
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002662'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006987'),
              path: '/productDetails',
              menuKey: 'supplier_productDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005860'),
              path: '/collectProducts',
              menuKey: 'yms_collectProducts',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006988'),
          path: '/productCenter/productBrand',
          menuKey: 'supplier_productCenter_productBrand',
          guidanceModule: true,
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006989'),
      icon: '&#xe68c;',
      type: 'platformAuthorization',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006990'),
          path: '/platformAuthorization',
          menuKey: 'supplier_platformAuthorization',
          guidanceModule: true,
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006991'),
      path: '/reconciliationManage',
      menuKey: 'supplier_reconciliationManage',
      icon: '&#xe665;',
      type: 'reconciliationManage',
      guidanceModule: true,
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003528'),
          path: '/reconciliationBillDetails',
          menuKey: 'supplier_reconciliationBillDetails',
          menuHide: true // 菜单不展示
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000290'),
      path: '/messageCenter',
      menuKey: 'supplier_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      icon: '&#xe8be;',
      type: 'messageCenter',
      guidanceModule: true,
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006976'),
          path: '/messageMailboxes',
          menuKey: 'supplier_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006992'),
      icon: '&#xe6e3;',
      type: 'accountManage',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000646'),
          path: '/accountManage/basicInformation',
          menuKey: 'supplier_accountManage_basicInformation',
          guidanceModule: true,
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006977'),
          path: '/accountManage/management',
          menuKey: 'supplier_accountManage_management',
          guidanceModule: true,
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003449'),
          path: '/accountManage/collectionAccount',
          menuKey: 'supplier_accountManage_collectionAccount',
          guidanceModule: true,
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006978'),
      icon: '&#xe664;',
      type: 'importExportTask',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006979'),
          path: '/importTask',
          menuKey: 'supplier_importTask'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006980'),
          path: '/exportTask',
          menuKey: 'supplier_exportTask'
        }
      ]
    }
  ],
  // 仓储管理
  warehouseManage: [
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002598'),
      path: '/wms/searchPackage',
      menuKey: 'supplier_wms_searchPackage',
      icon: '&#xe665;',
      type: 'searchPackage',
      guidanceModule: true,
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006993'),
          path: '/wms/searchPackageDetails',
          menuKey: 'supplier_wms_searchPackageDetails',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006187'),
          path: '/addManualSupplierOrder',
          menuKey: 'supplier_addManualSupplierOrder',
          menuHide: true // 菜单不展示
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006994'),
      path: '/wms/packingManage',
      menuKey: 'supplier_wms_packingManage',
      icon: '&#xe61b;',
      type: 'packingManage',
      guidanceModule: true,
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000589'),
          path: '/wms/exwarehouse',
          menuKey: 'supplier_wms_exwarehouse',
          menuHide: true, // 菜单不展示
          children: [
            {
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006042'),
              path: '/wms/scanEx',
              menuKey: 'supplier_wms_scanEx',
              menuHide: true // 菜单不展示
            }
          ]
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002220'),
      path: '/wms/inventoryLevels',
      menuKey: 'supplier_wms_inventoryLevels',
      icon: '&#xe65f;',
      type: 'inventoryLevels',
      guidanceModule: true,
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006995'),
      icon: '&#xe63c;',
      type: 'orderPicking',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002563'),
          guidanceModule: true,
          path: '/wms/generateOrderList',
          menuKey: 'supplier_wms_generateOrderList'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006932'),
          path: '/wms/generateOrderDetails',
          menuKey: 'supplier_wms_generateOrderDetails',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006933'),
          guidanceModule: true,
          path: '/wms/pickList',
          menuKey: 'supplier_wms_pickList'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002528'),
          path: '/wms/pickListDetails',
          menuKey: 'supplier_wms_pickListDetails',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006996'),
      icon: '&#xe630;',
      type: 'outboundPperation',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006997'),
          path: '/wms/sortOrderSetting',
          menuKey: 'supplier_wms_sortOrderSetting',
          guidanceModule: true,
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001962'),
          path: '/wms/sorting',
          menuKey: 'supplier_wms_sorting',
          guidanceModule: true,
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006170'),
          path: '/wms/packWorking',
          menuKey: 'supplier_wms_packWorking',
          guidanceModule: true,
        }
      ]
    },
    {
      name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006978'),
      icon: '&#xe664;',
      type: 'importExportTask',
      children: [
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006979'),
          path: '/importTask',
          menuKey: 'supplier_importTask'
        },
        {
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006980'),
          path: '/exportTask',
          menuKey: 'supplier_exportTask'
        }
      ]
    }
  ],
};
